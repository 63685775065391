<template>
  <div>
    <div>
      <div class="product-list-filter">
        <el-select
          v-model="filters.trademark"
          filterable
          clearable
          remote
          :remote-method="filterTrademarks"
          :placeholder="$t('ProductsPageFilterTrademarksPlaceholder')"
          @change="filtersChanged('trademark', $event)"
        >
          <el-option
            v-for="(item, key) in trademarks"
            :key="key"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>

        <el-select
          :class="
            $store.state.isRTL ? 'u-right-margin--2x' : 'u-left-margin--2x'
          "
          filterable
          clearable
          remote
          :remote-method="filterManufacturer"
          v-model="filters.manufacturer"
          :placeholder="$t('ProductsPageFilterManufacturerPlaceholder')"
          @change="filtersChanged('manufacturer', $event)"
        >
          <el-option
            v-for="(item, key) in manufacturers"
            :key="key"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
        <el-select
          :class="
            $store.state.isRTL ? 'u-right-margin--2x' : 'u-left-margin--2x'
          "
          filterable
          clearable
          remote
          :remote-method="filterCategories"
          v-model="filters.category"
          :placeholder="$t('ProductsPageFilterCategoryPlaceholder')"
          @change="filtersChanged('category', $event)"
        >
          <el-option
            v-for="(item, key) in categories"
            :key="key"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-input
          v-model="filters.id"
          :placeholder="$t('ProductsPageFilterProductIdPlaceholder')"
          size="normal"
          clearable
          style="width: 300px"
          :class="
            $store.state.isRTL ? 'u-right-margin--2x' : 'u-left-margin--2x'
          "
          @change="filtersChanged('id', $event)"
        ></el-input>

        <el-input
          :class="
            $store.state.isRTL ? 'u-right-margin--2x' : 'u-left-margin--2x'
          "
          :placeholder="$t('ProductsPageFilterProductNamePlaceholder')"
          style="width: 300px"
          v-model="filters.name"
          @change="filtersChanged('name', $event)"
        ></el-input>
        <el-radio-group
          v-model="filters.hasImage"
          @change="filtersChanged('hasImage', $event)"
        >
          <el-radio-button :label="true"
            >{{ $t("ProductsPageFilterImageRadioHasImagelabel") }}
          </el-radio-button>
          <el-radio-button :label="false">
            {{
              $t("ProductsPageProductsPageFilterImageRadioHasDefaultImageLabel")
            }}
          </el-radio-button>
          <el-radio-button :label="null">
            {{ $t("ProductsPageFilterImageRadioHasNoImageLabel") }}
          </el-radio-button>
        </el-radio-group>
        <el-radio-group
          v-model="filters.isAssigned"
          @change="filtersChanged('isAssigned', $event)"
        >
          <el-radio-button :label="true">available </el-radio-button>
          <el-radio-button :label="false"> unavailable </el-radio-button>
        </el-radio-group>
      </div>
      <div
        class="u-display-flex u-justify-content--between u-algin-items--center u-bottom-margin--2x"
      >
        <el-upload
          class="u-right-margin--2x u-left-margin--2x"
          :on-change="uploadProductFileChanged"
          :on-remove="removeUploadedProductsFile"
          :file-list="productsExcelFileList"
          action="#"
          :multiple="false"
          :auto-upload="false"
          accept=".xlsx"
        >
          <el-button slot="trigger" size="mini" type="primary">{{
            $t("ProductsPageSelectFileBtn")
          }}</el-button>

          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            :disabled="productsExcelFileList.length == 0"
            icon="el-icon-top"
            type="info"
            plain
            @click="uploadProducts"
            :loading="uploadingExcelFile"
          >
            Upload new products
          </el-button>
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            :disabled="productsExcelFileList.length == 0"
            icon="el-icon-top"
            type="info"
            plain
            @click="uploadProductsUnits"
            :loading="uploadingExcelFile"
          >
            Upload products units
          </el-button>
        </el-upload>
        <div>
          <el-button
            size="mini"
            type="info"
            plain
            @click="exportProductsUnits('view')"
            :loading="exportSheetLoading"
          >
            Export Products Units
          </el-button>
          <el-button
            size="mini"
            icon="el-icon-bottom"
            type="info"
            plain
            @click="exportProductsUnits('create')"
            :loading="downloadingExcelFile"
          >
            Product Units Template Download
          </el-button>
          <el-button
            size="mini"
            type="info"
            plain
            @click="exportProducts('view')"
            :loading="exportSheetLoading"
          >
            Export Products
          </el-button>
          <el-button
            size="mini"
            icon="el-icon-bottom"
            type="info"
            plain
            @click="exportProducts('create')"
            :loading="downloadingExcelFile"
          >
            Product Template Download
          </el-button>
        </div>
      </div>

      <div class="u-display-flex u-justify-content--center u-bottom-margin--2x">
        <el-pagination
          :hide-on-single-page="true"
          background
          layout="prev, pager, next"
          :total="productsPagination.totalResultsCount"
          :current-page="
            productsPagination.nextPage ? productsPagination.nextPage - 1 : 1
          "
          :page-size="50"
          @current-change="loadMoreProducts"
        ></el-pagination>
        <el-button
          size="mini"
          icon="el-icon-plus"
          type="success"
          plain
          @click="AddProduct"
          v-t="'ProductsPageAddNewProductBtn'"
        ></el-button>
        <el-button
          size="mini"
          icon="el-icon-plus"
          type="success"
          plain
          @click="goToDraft"
          v-t="'ProductsPagePendingReviewBtn'"
        ></el-button>

        <DeleteSheetsHandler />
      </div>

      <el-table
        class="is-infinite-load"
        :data="productsList"
        style="width: 100%"
        border
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="image"
          :label="$t('ProductsPageProductPhotoColumnTitle')"
          width="200"
        >
          <template slot-scope="scope">
            <div>
              <action-image
                style="width: 100px; height: 100px"
                @upload-cancelled="cancelImage(scope.row)"
                @upload-change="updateRowImage(scope.row, $event)"
                @save-clicked="saveRow(scope.row, $event)"
                :imageUrl="scope.row.image"
                :isUpdated="scope.row.isUpdated"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="id"
          :label="$t('ProductsPageIdColumnTitle')"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$t('ProductsPageProductNameColumnTitle')"
          width="220"
        ></el-table-column>
        <el-table-column
          prop="unit"
          :label="$t('ProductsPageUnitColumnTitle')"
          width="320"
        >
          <template slot-scope="scope">
            <div>
              <el-tag
                v-for="item in scope.row.productUnits"
                :key="item.unitId"
                type="info"
                style="margin: 2.5px"
              >
                {{ item.unit }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="itemsPerUnit"
          :label="$t('ProductsPageItemsPerUnitColumnTitle')"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="displayPrice"
          :label="$t('ProductsPageDisplayPriceColumnTitle')"
          width="120"
        ></el-table-column> -->

        <el-table-column
          prop="tradeMark"
          :label="$t('ProductsPageTrademarksColumnTitle')"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="manufacturer"
          :label="$t('ProductsPageManufacturerColumnTitle')"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="subCategory"
          :label="$t('ProductsPageCategoryColumnTitle')"
          width="200"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.subCategory.category.name }} =>
              {{ scope.row.subCategory.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="priority" label="priority"></el-table-column>
        <el-table-column :label="this.$t('ProductsPageOperationsColumnTitle')">
          <template slot-scope="scope">
            <div>
              <el-button @click="EditProduct(scope.row.id)" size="mini">{{
                $t("ProductsPageEditProductBtn")
              }}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="u-display-flex u-justify-content--center u-top-margin--2x">
      <el-pagination
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :total="productsPagination.totalResultsCount"
        :current-page="
          productsPagination.nextPage ? productsPagination.nextPage - 1 : 1
        "
        :page-size="50"
        @current-change="loadMoreProducts"
      ></el-pagination>
    </div>
    <absolute-actions-container :visible="listHasUpdatedImages">
      <el-button
        type="primary"
        size="default"
        circle
        icon="el-icon-check"
        @click="bulkEditImages"
      ></el-button>
      <el-button
        type="danger"
        size="default"
        circle
        icon="el-icon-close"
        @click="cancelAllUpdatedImages"
      ></el-button>
    </absolute-actions-container>
  </div>
</template>

<script>
import _ from "lodash";
import { productsService } from "../../services/products.service";
import { mapGetters } from "vuex";
import { utils } from "../../utils";
import ActionImage from "../../components/ActionImage.vue";
import AbsoluteActionsContainer from "../../components/AbsoluteActionsContainer.vue";
import DeleteSheetsHandler from "./DeleteSheetsHandler.vue";
export default {
  name: "ProductsPage",
  data() {
    return {
      productsList: [],
      productsPagination: {},
      filters: {},
      productsExcelFileList: [],
      uploadingExcelFile: false,
      downloadingExcelFile: false,
      exportSheetLoading: false,
      trademarks: [],
      manufacturers: [],
      categories: [],
    };
  },
  components: {
    ActionImage,
    AbsoluteActionsContainer,
    DeleteSheetsHandler,
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    listHasUpdatedImages() {
      return (
        this.productsList.filter((product) => {
          return product.isUpdated;
        }).length > 0
      );
    },
    trademarksLookup() {
      let currentTrademarks =
        this.lookups && this.lookups.trademarks ? this.lookups.trademarks : [];
      return currentTrademarks;
    },
    manufacturerLookup() {
      let currentManufacturers =
        this.lookups && this.lookups.manufacturer
          ? this.lookups.manufacturer
          : [];
      return currentManufacturers;
    },
    categoriesLookup() {
      let currentCategories =
        this.lookups && this.lookups.category ? this.lookups.category : [];
      return currentCategories;
    },
  },
  mounted() {
    const filters = JSON.parse(sessionStorage.getItem("filter"))?.products;
    if (filters) this.filters = filters;
    this.getProducts({ ...this.filters });
    if (this.filters.category) {
      this.categories = this.categoriesLookup.filter(
        (item) => item.id == this.filters.category
      );
    }
  },
  methods: {
    updateRowImage(row, newImageFile) {
      this.productsList = this.productsList.map((product) => {
        if (product.id == row.id) {
          product.isUpdated = true;
          product.newImageFile = newImageFile[0].raw;
        }
        return product;
      });
    },

    async bulkEditImages() {
      this.$loading();
      const imagesList = [];
      const productsIds = [];
      this.productsList.forEach((product) => {
        if (product.isUpdated) {
          imagesList.push(product.newImageFile);
          productsIds.push(product.id);
        }
      });
      let imageUploadFD = new FormData();
      imagesList.forEach((image) => {
        imageUploadFD.append("image", image);
      });
      imageUploadFD.append("ids", JSON.stringify(productsIds));
      try {
        const response = await productsService.bulkEditProductsImages(
          imageUploadFD
        );
        await this.getProducts(this.filters);
        this.$message({ message: response.data, type: "success" });
      } catch (err) {
        console.log(err);
      } finally {
        this.$loading().close();
      }
    },
    async saveRow(row) {
      this.$loading();
      const image = row.newImageFile;
      const productsId = [row.id];
      let imageUploadFD = new FormData();
      imageUploadFD.append("image", image);
      imageUploadFD.append("ids", JSON.stringify(productsId));
      try {
        const response = await productsService.bulkEditProductsImages(
          imageUploadFD
        );

        this.$message({ message: response.data, type: "success" });
        const updatedProduct = this.productsList.find((p) => p.id == row.id);
        updatedProduct.isUpdated = false;
        let reader = new FileReader();
        reader.onload = (e) => {
          updatedProduct.image = e.target.result;
          this.productsList = [...this.productsList];
        };
        reader.readAsDataURL(updatedProduct.newImageFile);
      } catch (err) {
        console.log(err);
      } finally {
        this.$loading().close();
      }
    },
    cancelImage(row) {
      const updatedProduct = this.productsList.find((p) => p.id == row.id);
      updatedProduct.isUpdated = false;
      this.productsList = [...this.productsList];
    },
    cancelAllUpdatedImages() {
      this.productsList = this.productsList.map((product) => {
        product.isUpdated = false;
        product.newImageFile = false;
        return product;
      });
    },
    AddProduct() {
      this.$router.push({
        name: "addProduct",
      });
    },
    goToDraft() {
      this.$router.push({
        name: "draftProduct",
      });
    },
    uploadProductFileChanged(file) {
      // Allow only one file in the file list not multiple
      this.productsExcelFileList = [file];
    },
    removeUploadedProductsFile() {
      this.productsExcelFileList = [];
    },
    async uploadProductsUnits() {
      this.uploadingExcelFile = true;
      let formData = new FormData();
      formData.append("file", this.productsExcelFileList[0].raw);
      try {
        let res = await productsService.importProductsUnits(formData);
        if (res.data) {
          let defaultType = "application/json; charset=utf-8";
          let resContentType = res.headers["content-type"];
          if (defaultType !== resContentType) {
            let blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });
            utils.downloadBlob(blob, "productUnitssErrors", "xlsx");
          }
          this.$message({
            message: "File Uploaded successfully ",
            type: "success",
            duration: 2000,
          });
        }
      } catch (err) {
        this.$message({
          message: "Error when importing products excel file",
          type: "error",
          duration: 2000,
        });
      } finally {
        this.uploadingExcelFile = false;
        this.productsExcelFileList = [];
      }
    },
    async uploadProducts() {
      this.uploadingExcelFile = true;
      let formData = new FormData();
      formData.append("file", this.productsExcelFileList[0].raw);
      try {
        let res = await productsService.importProducts(formData);

        if (res.data) {
          let defaultType = "application/json; charset=utf-8";
          let resContentType = res.headers["content-type"];
          if (defaultType !== resContentType) {
            let blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });
            utils.downloadBlob(blob, "FollowupSheet", "xlsx");
          }
          this.$globalFunctions.popupMessageWrapper(
              `File Uploaded successfully, Check the downloaded file`,
              "success",
              4000
            );
        }
      } catch (err) {
        this.$message({
          message: "Error when importing products excel file",
          type: "error",
          duration: 2000,
        });
      } finally {
        this.uploadingExcelFile = false;
        this.productsExcelFileList = [];
      }
    },
    async exportProducts(action) {
      try {
        if (action === "create") {
          this.downloadingExcelFile = true;
          let res = await productsService.exportProducts(action, null);
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          utils.downloadBlob(blob, "products", "xlsx");
        } else if (action === "view") {
          this.exportSheetLoading = true;
          const filtersObject = this.reformatFiltersForSheetExport(
            this.filters
          );
          let res = await productsService.exportProducts(action, filtersObject);
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          utils.downloadBlob(blob, "filteredProducts", "xlsx");
        }
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: "Error when exporting products excel file",
          type: "error",
          duration: 2000,
        });
      } finally {
        this.downloadingExcelFile = false;
        this.exportSheetLoading = false;
      }
    },
    async exportProductsUnits(action) {
      try {
        if (action === "create") {
          this.downloadingExcelFile = true;
          let res = await productsService.exportProductsUnits(action, null);
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          utils.downloadBlob(blob, "productUnitsTemplate", "xlsx");
        } else if (action === "view") {
          this.exportSheetLoading = true;
          const filtersObject = this.reformatFiltersForSheetExport(
            this.filters
          );
          let res = await productsService.exportProductsUnits(
            action,
            filtersObject
          );
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          utils.downloadBlob(blob, "productUnits", "xlsx");
        }
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: "Error when exporting products excel file",
          type: "error",
          duration: 2000,
        });
      } finally {
        this.downloadingExcelFile = false;
        this.exportSheetLoading = false;
      }
    },
    templateDownload() {
      // Todo add download logic
      // utils.downloadCSV(csvFile,Name)
    },
    getProducts(filters, page = 1) {
      const pageLoading = this.$loading();
      return productsService
        .getProducts(filters, page)
        .then((res) => {
          let productsList = res.products;

          this.productsList = productsList;
          this.productsPagination = res.pagination;
        })
        .finally(() => {
          pageLoading.close();
        });
    },
    loadMoreProducts(page) {
      this.getProducts(this.filters, page);
    },
    filtersChanged(key, value) {
      if (_.isEmpty(value + "")) {
        this.filters[key] = undefined;
      }
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.products = { ...this.filters };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));

      this.getProducts(this.filters);
    },

    tableRowClassName({ row }) {
      if (row.isUpdated) {
        return "u-bg--green-lighter no-hover";
      }
      return "no-hover";
    },
    EditProduct(id) {
      this.$router.push({
        name: "productDetails",
        params: { id: id },
      });
    },
    reformatFiltersForSheetExport(currentFilters) {
      currentFilters = JSON.parse(JSON.stringify(currentFilters));
      let finalObject = {};
      if (currentFilters.category)
        finalObject.categoryIds = [currentFilters.category];
      if (currentFilters.id) finalObject.productIds = [currentFilters.id];
      if (currentFilters.name) finalObject.name = currentFilters.name;
      if (currentFilters.manufacturer)
        finalObject.manufacturer = currentFilters.manufacturer;
      if (currentFilters.trademark)
        finalObject.trademark = currentFilters.trademark;
      if (currentFilters.hasOwnProperty("hasImage"))
        finalObject.hasImage = currentFilters.hasImage;
      return finalObject;
    },
    filterTrademarks(query) {
      if (query) {
        this.trademarks = this.trademarksLookup.filter((item) => {
          if (item) {
            return item.toLowerCase().includes(query.toLowerCase());
          }
        });
      } else {
        this.trademarks = [];
      }
    },

    filterManufacturer(query) {
      if (query) {
        this.manufacturers = this.manufacturerLookup.filter((item) => {
          if (item) {
            return item.toLowerCase().includes(query.toLowerCase());
          }
        });
      } else {
        this.manufacturers = [];
      }
    },
    filterCategories(query) {
      if (query) {
        this.categories = this.categoriesLookup.filter((item) => {
          if (item) {
            return item.name.toLowerCase().includes(query.toLowerCase());
          }
        });
      } else {
        this.categories = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/wrench-kit/settings/_settings.global.scss";

.c-distributers-header {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.c-distributers-header__title {
  margin-right: auto;
  font-size: 24px;
  font-weight: bold;
}
.infinite-list-wrapper {
  height: 90vh;
}

.el-table.is-infinite-load {
  position: inherit !important;
}

.product-list-filter {
  display: flex;
  min-height: 40px;
  border: 1px solid $wrench-color-sky;
  margin-bottom: $wrench-spacing-2x;
  background: #fff;
  padding: 8px;
}
</style>
