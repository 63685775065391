<template>
  <div class="u-display-flex" style="padding-left: 10px">
    <el-button size="mini" type="info" plain @click="handleSheetExport">
      Delete Template Download
    </el-button>

    <el-button size="mini" type="danger" plain @click="handleUploadClick">
      Delete
    </el-button>
    <input
      ref="excel-upload-input"
      class="excel-upload-input"
      type="file"
      accept=".xlsx, .xls"
      @change="handleClick"
      @click="$event.target.value = ''"
    />
    <a style="display: none" ref="sheet-download" target="_blank"></a>
  </div>
</template>

<script>
import { productsService } from "@/services";
export default {
  name: "OffersSheetsHandler",
  props: {
    chosenFilters: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sheetFileList: [],
    };
  },
  methods: {
    uploadSheetFileChanged(changedData) {
      this.sheetFileList = [changedData];
    },
    removeSheetFile() {
      this.sheetFileList = [];
    },
    async handleSheetImport(formBody) {
      try {
        const response = await productsService.importDeleteProductsSheet(
          formBody
        );
        if (response && response.data) {
          const responseData = response.data;
          let defaultType = "application/json; charset=utf-8";
          let responseContentType = response.headers["content-type"];
          if (defaultType === responseContentType) {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(responseData)
            );
            let parsedResponse = JSON.parse(decodedString);
            if (parsedResponse.data.message) {
              this.$globalFunctions.popupMessageWrapper(
                parsedResponse.data.message,
                "success",
                2000
              );
            } else {
              this.$globalFunctions.popupMessageWrapper(
                "Success",
                "success",
                2000
              );
            }
          } else {
            let blob = new Blob([responseData], {
              type: response.headers["content-type"],
            });
            this.$refs["sheet-download"].setAttribute(
              "href",
              window.URL.createObjectURL(blob)
            );
            this.$refs["sheet-download"].setAttribute(
              "download",
              `uploadErrorSheet.xlsx`
            );
            this.$refs["sheet-download"].click();
            this.$globalFunctions.popupMessageWrapper(
              "file uploaded successfully, please check the downloaded file",
              "success",
              4000
            );
          }
        }
      } catch (error) {
        console.log("error", error);
        this.$globalFunctions.popupMessageWrapper(
          "Error Occured",
          "error",
          2000
        );
      }
      this.sheetFileList = [];
    },
    async handleSheetExport() {
      try {
        const response = await productsService.exportDeleteProductsSheet();
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          this.$refs["sheet-download"].setAttribute(
            "href",
            window.URL.createObjectURL(blob)
          );
          let downloadedFileName = "EntityProductDeleteTemplate.xlsx";
          this.$refs["sheet-download"].setAttribute(
            "download",
            downloadedFileName
          );
          this.$refs["sheet-download"].click();
          this.$globalFunctions.popupMessageWrapper(
            "file downloaded successfully",
            "success",
            4000
          );
        }
      } catch (error) {
        console.log("error", error);
        this.$globalFunctions.popupMessageWrapper(
          "Error Occured",
          "error",
          2000
        );
      }
    },
    handleUploadClick() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      try {
        const files = e.target.files;
        const rawFile = files[0]; // only use files[0]
        if (!rawFile) return;
        if (this.isExcel(rawFile)) {
          let newForm = new FormData();
          newForm.append("file", rawFile);
          this.handleSheetImport(newForm);
        } else {
          this.$globalFunctions.popupMessageWrapper(
            "File type should be .xls or .xlsx",
            "error",
            2000
          );
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    isExcel(file) {
      return /\.(xlsx|xls)$/.test(file.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}
</style>
